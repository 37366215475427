<template>
  <div class="setting-account">
    <van-cell v-for="item in menu" :key="item.id" :title="item.title" :is-link="item.isLink" :value="item.value" :to="item.to" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      menu: []
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  mounted() {
    const { eaUserName, isSetPassword } = this.userInfos
    this.menu = [
      { id: 1, title: '手机号', value: eaUserName, isLink: false },
      { id: 2, title: '登录密码', value: isSetPassword === 1 ? '已设置' : '未设置', isLink: true, to: 'settingPassword' }
    ]
  }

}
</script>

<style lang="scss" scoped>
.setting-account {
  min-height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background: #f7f8fa;
}
</style>
